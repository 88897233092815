import React from "react";
import styles from "./footer.module.css";
import { NavLink, useLocation } from "react-router-dom";
import { PATHS, USER_ROLE } from "../../../firebase/types";
import { useAppContext } from "../../../store/AppContext";

function Footer() {
  const { pathname } = useLocation();
  const { currentUser, isMobile } = useAppContext();

  return (
    <div
      className={`${styles.FooterOuter} ${
        isMobile ? styles.FooterMobile : styles.FooterDesktop
      }`}
    >
      <NavLink
        to={`/${PATHS.PUBLIC}`}
        className={`${styles.Public}${
          pathname.includes(PATHS.PUBLIC) ? ` ${styles.ActivePublic}` : ""
        }`}
      >
        <div>&nbsp;</div>
      </NavLink>

      {currentUser && currentUser.role === USER_ROLE.PRO ? (
        <NavLink
          to={`/${PATHS.WORK}`}
          className={`${styles.Work}${
            pathname.includes(PATHS.WORK) ? ` ${styles.ActiveWork}` : ""
          }`}
        >
          <div>&nbsp;</div>
        </NavLink>
      ) : null}

      <NavLink
        to="/"
        className={`${styles.Now}${
          pathname === "/" || pathname.includes(`/${PATHS.EVENTS}`)
            ? ` ${styles.ActiveNow}`
            : ""
        }`}
      >
        <div>&nbsp;</div>
      </NavLink>

      <NavLink
        to={`/${PATHS.STATS}`}
        className={`${styles.Stats}${
          pathname.includes(PATHS.STATS) ? ` ${styles.ActiveStats}` : ""
        }`}
      >
        <div>&nbsp;</div>
      </NavLink>

      <NavLink
        to={`/${PATHS.USER}`}
        className={`${styles.User}${
          pathname.includes(PATHS.USER) ? ` ${styles.ActiveUser}` : ""
        }`}
      >
        <div>&nbsp;</div>
      </NavLink>
      <span className={`${styles.Hidden} ${styles.LoadPublic}`}></span>
      <span className={`${styles.Hidden} ${styles.LoadWork}`}></span>
      <span className={`${styles.Hidden} ${styles.LoadNow}`}></span>
      <span className={`${styles.Hidden} ${styles.LoadStats}`}></span>
      <span className={`${styles.Hidden} ${styles.LoadUser}`}></span>
    </div>
  );
}

export default Footer;
