import React, { useEffect, useRef, useState } from "react";
import {
  EventType,
  Microcycle,
  PLAN_ID_DEFAULT,
  Repeat,
  UNABLE_UNITS,
  Weekday,
} from "../../../firebase/types";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../../store/AppContext";
import { motion } from "framer-motion";
import Availability from "./Availability";
import SaveCancel from "../../btns-save-cancel/SaveCancel";
import TextEditor from "../../text-editor/TextEditor.component";
import { RepeatEvent } from "./RepeatEvent";
import styles from "./event-type.module.css";

function EditEventType() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    currentUser,
    eventTypes,
    update_event_type,
    delete_event_type,
    setModal,
    get_microcycles_for_creator,
    microcycles,
  } = useAppContext();

  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");

  const [shortDescription, setShortDescription] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");

  const [duration, setDuration] = useState("10");
  const [durationErr, setDurationErr] = useState("");
  const [durationUnits, setDurationUnits] = useState("minutes");

  const [maxAttendees, setMaxAttendees] = useState("1");
  const [maxAttendeesErr, setMaxAttendeesErr] = useState("");

  const [planID, setPlanID] = useState(PLAN_ID_DEFAULT);

  const [displayOccupancy, setDisplayOccupancy] = useState(false);

  const [buffer, setBuffer] = useState("");
  const [bufferUnits, setBufferUnits] = useState("minutes");

  const [unable, setUnable] = useState("4");
  const [unableUnits, setUnableUnits] = useState<string>(UNABLE_UNITS.HOURS);

  const [addBuffer, setAddBuffer] = useState(false);
  const [week, setWeek] = useState<Weekday[] | null>(null);
  const [repeat, setRepeat] = useState<Repeat | null>(null);
  const [createdAt, setCreatedAt] = useState(0);

  const RefAvailability: any = useRef(null);
  const RefRepeat: any = useRef(null);

  useEffect(() => {
    if (planID === PLAN_ID_DEFAULT || !currentUser) return;

    (async () => {
      await get_microcycles_for_creator(currentUser.uid);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planID, currentUser]);

  useEffect(() => {
    if (id !== "new") {
      const found = eventTypes?.find((ev: EventType) => ev.id === id);
      if (!found) return;

      setName(found.name);
      setDuration(found.duration);
      setDurationUnits(found.durationUnits);
      setMaxAttendees(found.maxAttendees);
      if (found.planID) setPlanID(found.planID);
      setUnable(found.unable);
      setUnableUnits(found.unableUnits);
      // optionals:
      if (found.shortDescription) setShortDescription(found.shortDescription);
      if (found.description) setDescription(found.description);
      if (found.location) setLocation(found.location);
      if (found.displayOccupancy) setDisplayOccupancy(found.displayOccupancy);
      setAddBuffer(found.buffer && found.buffer !== "0" ? true : false);
      if (found.buffer) setBuffer(found.buffer);
      if (found.week) setWeek(found.week);
      if (found.repeat) setRepeat(found.repeat);

      if (found.hasOwnProperty("createdAt")) setCreatedAt(found.createdAt);
    }
  }, [id, eventTypes]);

  const handleCancel = () => navigate(-1);
  const handleSave = async () => {
    const week = RefAvailability?.current?.getWeek();
    const repeat = RefRepeat?.current?.getRepeat();

    if (!isValid() || !week || !repeat || !currentUser) return;

    let request: EventType = {
      updatedAt: Date.now(),
      createdAt,
      name,
      shortDescription,
      description,
      location,
      duration,
      durationUnits,
      link: `${name.split(" ")[0].toLowerCase()}-${name.length}`,
      maxAttendees,
      planID,
      displayOccupancy,
      buffer,
      unable,
      unableUnits,
      week,
      repeat,
      creatorUID: currentUser.uid,
      creatorName: currentUser.displayName
        ? currentUser.displayName
        : currentUser.email,
      creatorHandle: currentUser.handle ? currentUser.handle : "",
      id,
    };
    await update_event_type(request);
    navigate(-1);
  };

  const handleChangePlan = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    setPlanID(ev.target.value);
  };

  const isValid = () => {
    let isValid = true;
    if (name === "") {
      isValid = false;
      setNameErr("Enter name");
    }
    if (duration === "") {
      isValid = false;
      setDurationErr("Enter duration");
    }
    if (maxAttendees === "") {
      isValid = false;
      setMaxAttendeesErr("Enter maximum attendeeUIDs");
    }

    return isValid;
  };

  const delete_event = async () => {
    if (id && id !== "new") {
      await delete_event_type(id);
      setModal();
      navigate(-1);
    }
  };
  const showModal = () => {
    const modalContent = (
      <div>
        <p style={{ padding: "10px 0 16px 0" }}>
          Confirm removing this event type.
        </p>

        <div className="Button" onClick={delete_event}>
          Confirm remove
        </div>
      </div>
    );
    setModal(modalContent);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.EditEventTypeOuter}
    >
      <SaveCancel save={handleSave} cancel={handleCancel} fixedTop={true} />
      <br />
      <label>
        <span>Name</span>
        <input
          placeholder="name"
          value={name}
          onChange={(ev) => {
            setName(ev.target.value);
            setNameErr("");
          }}
        />
      </label>
      {nameErr && <p className="ErrorParagraph">{nameErr}</p>}

      <label>
        <span>Short description</span>
        <textarea
          rows={2}
          style={{ maxWidth: "calc(100vw - 32px)" }}
          placeholder="..."
          value={shortDescription}
          onChange={(ev) => setShortDescription(ev.target.value)}
        />
      </label>

      <p style={{ fontSize: "var(--fs-small)", padding: "0px 14px 1px 14px" }}>
        Description
      </p>
      <TextEditor
        restrictButtons={true}
        handleBlur={(value: string) => setDescription(value)}
        value={description}
      />
      <p style={{ paddingTop: "10px" }} />

      <label>
        <span>Location</span>
        <input
          placeholder="..."
          value={location}
          onChange={(ev) => setLocation(ev.target.value)}
        />
      </label>

      <label>
        <span>Duration</span>
      </label>
      <div className="Grid2Columns" style={{ margin: "4px 0 14px 0" }}>
        <input
          type="number"
          placeholder="10"
          min="10"
          value={duration}
          onChange={(ev) => {
            setDuration(ev.target.value);
            setDurationErr("");
          }}
          style={{ margin: "0" }}
        />
        <select
          onChange={(ev) => setDurationUnits(ev.target.value)}
          value={durationUnits}
        >
          <option>minutes</option>
          <option>hours</option>
        </select>
      </div>
      {durationErr && <p className="ErrorParagraph">{durationErr}</p>}

      <label>
        <span>Max attendees</span>
      </label>
      <input
        type="number"
        min="1"
        placeholder="1"
        name="maxAttendees"
        value={maxAttendees}
        onChange={(ev) => {
          setMaxAttendees(ev.target.value);
          setMaxAttendeesErr("");
          if (ev.target.value === "1") setDisplayOccupancy(false);
        }}
      />
      {maxAttendeesErr && <p className="ErrorParagraph">{maxAttendeesErr}</p>}
      <div
        className="RowCenterStart"
        onClick={() => {
          if (Number(maxAttendees) > 1) setDisplayOccupancy((state) => !state);
        }}
      >
        <input
          type="checkbox"
          checked={displayOccupancy}
          style={{ width: "30px", margin: "4px 0" }}
          onChange={() => {}}
          disabled={Number(maxAttendees) > 1 ? false : true}
        />
        <label style={{ width: "auto" }}>
          <span>Display availability</span>
        </label>
      </div>

      <p style={{ margin: "18px 0 9px 12px", fontSize: "var(--fs-small)" }}>
        Training plan
      </p>
      <div
        className="RowCenterStart"
        onClick={() => setPlanID(PLAN_ID_DEFAULT)}
      >
        <input
          type="radio"
          className="Radio"
          id="planID_default"
          name="planID_default"
          checked={planID === PLAN_ID_DEFAULT}
          onChange={() => {}}
        />
        <label htmlFor="planID_default">
          <span>Use the plan assigned to the client</span>
        </label>
      </div>

      <div
        className="RowCenterStart"
        style={{ margin: "10px 0" }}
        onClick={() => {
          if (planID === PLAN_ID_DEFAULT) setPlanID("other");
        }}
      >
        <input
          type="radio"
          className="Radio"
          id="planID_specific"
          name="planID_specific"
          checked={planID !== PLAN_ID_DEFAULT}
          onChange={() => {}}
        />
        <label htmlFor="planID_specific">
          <span>Use a specific plan for this event type</span>
        </label>
      </div>

      {planID !== PLAN_ID_DEFAULT && microcycles ? (
        <>
          <motion.select
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            onChange={handleChangePlan}
            defaultValue={planID}
            style={{ width: "100%", marginBottom: "10px" }}
          >
            <option value="other">- Select a plan -</option>
            {microcycles.map((plan: Microcycle, idx: number) => (
              <option key={idx} value={plan.id}>
                {plan.name}
              </option>
            ))}
          </motion.select>
          <br />
        </>
      ) : null}
      <p style={{ paddingTop: "10px" }} />

      <RepeatEvent ref={RefRepeat} data={repeat} />
      <br />
      <p style={{ paddingTop: "10px" }} />

      <label>
        <span>Schedule</span>
      </label>
      <Availability ref={RefAvailability} data={week} />
      <br />
      <p style={{ paddingTop: "7px" }} />

      <div
        className="RowCenterCenter"
        onClick={() => setAddBuffer((state) => !state)}
      >
        <input
          type="checkbox"
          onChange={() => {}}
          checked={addBuffer}
          style={{ width: "30px", margin: "0" }}
        />
        <label style={{ width: "100%" }}>
          <span>Add buffer time after each event</span>
        </label>
      </div>
      {addBuffer && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="Grid2Columns"
          style={{ margin: "4px 0 12px 0" }}
        >
          <input
            type="number"
            placeholder="0"
            min="0"
            value={buffer}
            onChange={(ev) => setBuffer(ev.target.value)}
            style={{ margin: "0" }}
          />
          <select
            onChange={(ev) => setBufferUnits(ev.target.value)}
            value={bufferUnits}
            disabled={true}
          >
            <option>minutes</option>
            <option>hours</option>
          </select>
        </motion.div>
      )}
      <p style={{ paddingTop: "10px" }} />

      <p style={{ fontSize: "var(--fs-small)", paddingBottom: "4px" }}>
        Unable to join the event within: (before the event)
      </p>
      <div className="Grid2Columns" style={{ margin: "4px 0 12px 0" }}>
        <input
          type="number"
          placeholder="5"
          min="0"
          value={unable}
          onChange={(ev) => setUnable(ev.target.value)}
          style={{ margin: "0" }}
        />
        <select
          onChange={(ev) => setUnableUnits(ev.target.value)}
          value={unableUnits}
        >
          <option value={UNABLE_UNITS.MINUTES}>minutes</option>
          <option value={UNABLE_UNITS.HOURS}>hours</option>
        </select>
      </div>
      {id !== "new" && (
        <>
          <br />
          <p className="ErrorButton" onClick={showModal}>
            Remove event type
          </p>
          <br />
        </>
      )}
    </motion.div>
  );
}

export default EditEventType;
