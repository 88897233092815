import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { PATHS } from "../../../../firebase/types";
import styles from "./explanation.module.css";
import Svg from "../../../../assets/svg/gym.svg";
import SvgOn from "../../../../assets/svg/gym_on.svg";

function Info_Work() {
  const { pathname } = useLocation();
  const [hover, setHover] = useState(false);
  const toggleHover = () => setHover((state) => !state);

  if (pathname !== `/${PATHS.WORK}`) return null;

  return (
    <>
      <div
        className={styles.LineTitle}
        style={{ gridTemplateColumns: "40px 1fr" }}
      >
        <img
          src={hover ? Svg : SvgOn}
          alt="Fun"
          onMouseOver={toggleHover}
          onMouseOut={toggleHover}
        />
        <p className="Title_Bigger">Fun / Work</p>
      </div>
      <br />

      <p className="TextSecondary">
        Assign a client to a plan for 1:1 Event Types
      </p>
    </>
  );
}

export default Info_Work;
