import React, { useEffect } from "react";
import { useState } from "react";
import { DateRange } from "react-date-range";
import "./range-picker.css";

function RangePicker({ onChange, selection }) {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (!selection) return;

    const { startDate, endDate, key } = selection;
    setState([
      { startDate: new Date(startDate), endDate: new Date(endDate), key },
    ]);
  }, [selection]);

  return (
    <>
      <DateRange
        onChange={(item) => {
          setState([item.selection]);
          onChange(item.selection);
        }}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={1}
        ranges={state}
        direction="horizontal"
        style={{ width: "100%" }}
      />
    </>
  );
}

export default RangePicker;
