export interface LocalStorageCache {
  updatedAt?: number;
  // settings?: SettingsType;
  logoUrl?: string;
  // media?: DbMediaType[];
  user?: any;
}
export const enum LocalStorageItems {
  FRONTEND = "frontend",
  BACKEND = "backend",
  COOKIES = "cookies",
  LANGUAGE = "language",
  SIGNED_IN = "signed_in",
}
export const LOCAL_STORAGE_COOKIES_ACCEPTED = "accepted";

// FITNESS HELPER

export interface User {
  // specific to a google account:
  uid: string;
  email: string;
  displayName?: string;
  photoURL?: string | null;
  phoneNumber: string;
  firstLogin: number;
  lastLogin: number;
  // from db:
  role: string;
  handle: string;
  id?: string;
  anamnezaID?: string;
  //
  about: string;
  city: string;
  country: string;
  upgradedAt?: number;
  downgradedAt?: number;
}
export enum USER_ROLE {
  CLIENT = "client",
  PRO = "pro",
}
export interface Anamneza {
  uid: string;
  id?: string;
  objective: string; // DatedNote[];
  status: string; //DatedNote[];
  focus: string;
  history: string;
  critical: string;
  updatedAt: number;
}
export interface DatedNote {
  updatedAt: number;
  creatorUID: string;
  note: string;
}

export interface EventType {
  createdAt: number;
  updatedAt: number;
  id?: string;
  name: string;
  shortDescription?: string;
  description?: string;
  location?: string;
  duration: string;
  durationUnits: string;
  link: string;
  maxAttendees: string;
  displayOccupancy?: boolean;
  buffer?: string;
  unable: string;
  unableUnits: string;
  week?: Weekday[] | null;
  planID: string;
  repeat: Repeat;
  //
  creatorUID: string;
  creatorName: string;
  creatorHandle: string;
  //
  color?: number;
  tags?: string;
}
export interface Repeat {
  type: REPEAT_TYPE;
  selection?: RepeatDateSelection;
}
export interface DateRangeSelection {
  startDate: string;
  endDate: string;
  key: string;
}
export const DATE_FORMAT_dddd_DD_MMMM_YYYY = "dddd, DD MMMM YYYY";
export const DATE_FORMAT_MMMM_YYYY = "MMMM YYYY";
export const DATE_FORMAT_DD_MMMM_YYYY = "DD MMMM YYYY";
export const DATE_FORMAT_DD_MMMM = "DD MMMM";
export const DATE_FORMAT_HH_mm_DD_MMMM = "HH:mm, DD MMMM";
export const DATE_FORMAT_ddd = "ddd";
export const DATE_FORMAT_D = "D";

export interface RepeatDateSelection {
  startDate: number;
  endDate: number;
  key: string;
}
export enum REPEAT_TYPE {
  INFINITE = 0,
  ONE_TIME = 1,
  RANGE = 2,
}
export enum UNABLE_UNITS {
  MINUTES = "mins",
  HOURS = "hours",
}
export const PLAN_ID_DEFAULT = "check_plan";

export interface Event extends EventType {
  timestamp: number;
  eventTypeID: string;
  attendeeUIDs?: string[]; // used to get events by attendee UID from db
  attendees?: Participant[];
  canceled?: Participant[];
  day?: any;
  canceledByCreator?: number; // -1 = false; Date.now() = when creator canceled it
  workout?: Workout;
}

export interface Participant {
  date: number;
  name: string;
  uid: string;
}

export interface Weekday {
  id: string;
  hours: HoursType[];
}
export interface HoursType {
  start: string;
  end: string;
}

export enum PATHS {
  BASE = "fitness-helper.com",
  AUTHENTICATE = "authenticate",
  //
  PUBLIC = "search",
  WORK = "fun",
  // today = "/"
  STATS = "stats",
  USER = "user",
  //
  EDIT_EVENT_TYPE = "event-type",
  EVENTS = "events",
  EVENT = "event",
  PRO = "fit",
  WORKOUT = "plan",
  BILLING = "pricing",
}

export interface Anamneza {
  // un document per clientUID
  //
}

// too complicated for now:
export interface Macrocycle extends BaseCycle {
  whatWhy: WhatWhy[];
  mezocycles: Mezocycle[];
}
export interface WhatWhy {
  date: number;
  motive: string;
  objective: string;
}
export interface Mezocycle extends BaseCycle {
  microcycle: Microcycle[];
}

// using MICRO for PLANS:
export interface Microcycle extends BaseCycle {
  workouts: Workout[];
  clients: MicrocycleClient[];
  event_types: MicrocycleEventType[];
}
export interface MicrocycleClient {
  uid: string;
  name: string;
  index: number;
  email: string;
  handle?: string;
}
export interface MicrocycleEventType {
  id: string;
  name: string;
  index: number;
}
export interface BaseCycle {
  id?: string;
  name: string;
  description: string;
  creatorUID: string;
  creatorName: string;
  createdAt?: number;
  updatedAt?: number;
  // progress: Progress[];
  // status: string;
}
export interface Progress {
  date: number;
  note: string;
  proUID: string;
  proName: string;
}
export interface Workout {
  id?: string;
  requirements: string;
  core: string;
  // warmUp?: string;
  // after?: string;
  creatorUID?: string;
  creatorName?: string;
  // assignable:
  eventID?: string;
  assignedTo?: Participant[];
}

export interface EarlyAccess {
  email: string;
  addedAt: number;
}
