import React from "react";
import { useLocation } from "react-router-dom";
import { PATHS } from "../../../../firebase/types";

function Info_Authenticate() {
  const { pathname } = useLocation();

  if (pathname !== `/${PATHS.AUTHENTICATE}`) return null;

  return (
    <>
      <p className="Title_Bigger">Authenticate</p>
      <p>
        Supporting authenticating with a Google account and also by providing a
        Username & Password
      </p>
    </>
  );
}

export default Info_Authenticate;
