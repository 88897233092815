import React, { useEffect, useState } from "react";
import { User } from "../../../firebase/types";
import { useAppContext } from "../../../store/AppContext";
import SaveCancel from "../../btns-save-cancel/SaveCancel";

interface EditAboutProps {
  user: User;
  closeEdit: () => void;
}

function EditAbout({ user, closeEdit }: EditAboutProps) {
  const { currentUser, update_user } = useAppContext();

  const [displayName, setDisplayName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [about, setAbout] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");

  useEffect(() => {
    if (!user) return;

    setDisplayName(user.displayName ? user.displayName : "");
    setPhoneNumber(user.phoneNumber ? user.phoneNumber : "");
    setAbout(user.about ? user.about : "");
    setCity(user.city ? user.city : "");
    setCountry(user.country ? user.country : "");
  }, [user]);

  if (!currentUser || !user) return null;

  const saveUser = async () => {
    await update_user({
      ...currentUser,
      displayName,
      phoneNumber,
      about,
      city,
      country,
    });
    closeEdit();
  };

  return (
    <div>
      <SaveCancel save={saveUser} cancel={closeEdit} />

      <label>
        <span>Display name</span>
        <input
          placeholder="..."
          value={displayName}
          onChange={(ev) => setDisplayName(ev.target.value)}
        />
      </label>
      <br />

      <label>
        <span>Phone number</span>
        <input
          placeholder="..."
          value={phoneNumber}
          onChange={(ev) => setPhoneNumber(ev.target.value)}
        />
      </label>
      <br />

      <label>
        <span>About</span>
        <input
          placeholder="..."
          value={about}
          onChange={(ev) => setAbout(ev.target.value)}
        />
      </label>
      <br />

      <label>
        <span>City</span>
        <input
          placeholder="..."
          value={city}
          onChange={(ev) => setCity(ev.target.value)}
        />
      </label>
      <br />

      <label>
        <span>Country</span>
        <input
          placeholder="..."
          value={country}
          onChange={(ev) => setCountry(ev.target.value)}
        />
      </label>
      <br />
    </div>
  );
}

export default EditAbout;
