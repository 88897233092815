import { Navigate, useLocation } from "react-router-dom";
import { getCachedItem } from "../store/local-storage";
import { LocalStorageItems, PATHS } from "../firebase/types";

function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const data = getCachedItem(LocalStorageItems.SIGNED_IN);

  if (!data || data === "-") {
    return (
      <Navigate
        to={`/${PATHS.AUTHENTICATE}`}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
}

export default RequireAuth;
