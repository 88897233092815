import { motion } from "framer-motion";
import Events from "./Events";

function Today() {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Events />
    </motion.div>
  );
}

export default Today;
