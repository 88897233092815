import dayjs, { Dayjs } from "dayjs";
import {
  DATE_FORMAT_DD_MMMM,
  DATE_FORMAT_DD_MMMM_YYYY,
  DATE_FORMAT_HH_mm_DD_MMMM,
  DATE_FORMAT_dddd_DD_MMMM_YYYY,
  Event,
  EventType,
  HoursType,
  Participant,
  REPEAT_TYPE,
  Repeat,
  RepeatDateSelection,
  UNABLE_UNITS,
  USER_ROLE,
  User,
  Weekday,
} from "./types";
import SvgPeople from "../assets/svg/people.svg";
import SvgTime from "../assets/svg/time.svg";
import SvgPin from "../assets/svg/pin.svg";

export const ConsoleLog = (label: string, value: any) => {
  if (!window || !window.location || window.location.hostname !== "localhost") {
    return;
  }

  try {
    console.log(`%c${label}: %c${value}`, "color: green", "font-size: 1rem");
  } catch (error) {}
};

export function copyToClipboard(text: string) {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log("Text copied to clipboard");
    })
    .catch((err) => {
      console.error("Error copying text: ", err);
    });
}

export function makeHourStructure(
  hourStart: number = 0,
  hourEnd: number = 23,
  minInterval = 15,
  minStart: number = 0,
  minEnd: number = 45
) {
  let result = [];

  for (let hour = hourStart; hour <= hourEnd; hour++) {
    for (let minute = minStart; minute <= minEnd; minute += minInterval) {
      let hourStr = hour < 10 ? "0" + hour : hour;
      let minuteStr = minute < 10 ? "0" + minute : minute;
      let timeStr = hourStr + ":" + minuteStr;
      result.push(timeStr);
    }
  }
  return result;
}

export const DEFAULT_HOURS = {
  start: "08:00",
  end: "12:00",
};

export const DEFAULT_WEEK: Weekday[] = [
  { id: "MON", hours: [DEFAULT_HOURS] },
  { id: "TUE", hours: [DEFAULT_HOURS] },
  { id: "WED", hours: [DEFAULT_HOURS] },
  { id: "THU", hours: [DEFAULT_HOURS] },
  { id: "FRI", hours: [DEFAULT_HOURS] },
  { id: "SAT", hours: [] },
  { id: "SUN", hours: [] },
];

function createTimeSlots(startTime: string, endTime: string, duration: number) {
  const timeSlots = [];
  let currentTime = startTime;

  while (currentTime < endTime) {
    timeSlots.push(currentTime);
    const hours = parseInt(currentTime.substring(0, 2));
    const minutes = parseInt(currentTime.substring(3, 5));
    const totalMinutes = hours * 60 + minutes;
    const newMinutes = totalMinutes + duration;
    const newHours = Math.floor(newMinutes / 60);
    const remainderMinutes = newMinutes % 60;
    currentTime = `${newHours.toString().padStart(2, "0")}:${remainderMinutes
      .toString()
      .padStart(2, "0")}`;
  }

  return timeSlots;
}

export const getTotalDuration = (event: Event) => {
  let durInMins = Number(event.duration);
  let bufferMins = Number(event.buffer);

  if (event.durationUnits === UNABLE_UNITS.HOURS) {
    durInMins *= 60;
  }

  return durInMins + bufferMins;
};

export const generateHourIntervals = (day: any) => {
  const event = day?.eventType;
  if (!event || !day.hours) return [];

  const hours: HoursType[] = day.hours;
  let result: any = [];
  for (let i = 0; i < hours.length; i++) {
    const timeSlots = createTimeSlots(
      hours[i].start,
      hours[i].end,
      getTotalDuration(event)
    );
    result = [...result, timeSlots];
  }

  return result.join().split(",");
};

export const getUserNameOrEmail = (user: User) =>
  user.displayName ? user.displayName : user.email;

export const eventTypeDuration = (event: EventType | Event) => {
  let output = `${event.duration} ${event.durationUnits}`;
  if (event.buffer === "0") {
    output += `, no pause time`;
  } else {
    output += `, ${event.buffer} minutes pause`;
  }

  return output;
};

export const IsHost = ({
  user,
  creatorUID,
}: {
  user: User;
  creatorUID: string;
}) => {
  const isHost =
    user?.role === USER_ROLE.PRO && user.uid === creatorUID ? (
      <p
        style={{
          color: "var(--blue-dark-link)",
          fontWeight: "500",
          paddingBottom: "1px",
          textAlign: "center",
        }}
      >
        Your event. You are the host.
      </p>
    ) : null;
  return isHost;
};

export const ClientCanceled = ({ event }: { event: Event }) => {
  const isCanceled =
    event?.attendeeUIDs?.length === 0 && !event.canceledByCreator ? (
      <p style={{ color: "var(--color-red)" }}>No client</p>
    ) : null;
  return isCanceled;
};
export const HostCanceled = ({ event }: { event: Event }) => {
  const when = dayjs(event.canceledByCreator).format(DATE_FORMAT_HH_mm_DD_MMMM);
  const isCanceled = event?.canceledByCreator ? (
    <p style={{ color: "var(--color-red)" }}>Host canceled at {when}</p>
  ) : null;
  return isCanceled;
};

export const ShowParticipantNameAndDate = ({
  event,
  type,
  user,
}: {
  event: Event;
  type: string;
  user: User;
}) => {
  if (!user || user.uid !== event.creatorUID) return null;

  let el = <></>;
  if (type === "canceled" && event.canceled) {
    if (!event.canceled.length) return null;
    el = (
      <>
        <p className="Bold">Canceled by:</p>
        {event.canceled.map((item: Participant, idx: number) => (
          <p key={idx}>
            {item.name}
            <span className="TextSecondary">{` at `}</span>
            {dayjs(item.date).format(DATE_FORMAT_HH_mm_DD_MMMM)}
          </p>
        ))}
        <br />
      </>
    );
  }
  if (type === "attendees" && event.attendees) {
    if (!event.attendees.length) return null;
    el = (
      <>
        <p className="Bold">Signed up:</p>
        {event.attendees.map((item: Participant, idx: number) => (
          <p key={idx}>
            {item.name}
            <span className="TextSecondary">{` at `}</span>
            {dayjs(item.date).format(DATE_FORMAT_HH_mm_DD_MMMM)}
          </p>
        ))}
        <br />
      </>
    );
  }

  return el;
};

export const ShowEventTypeMaxOccupancy = ({
  eventType,
}: {
  eventType: EventType;
}) => {
  if (eventType.maxAttendees === "1" || !eventType.displayOccupancy)
    return null;

  return (
    <>
      <p className="TextSecondary">Max:</p>
      <p>{eventType.maxAttendees}</p>
      <img src={SvgPeople} alt="people" />
    </>
  );
};

export const DurationWithIcon = ({
  timestamp,
  when,
}: {
  timestamp?: number;
  when?: string;
}) => {
  const day = dayjs(timestamp);
  let toDisplay = "",
    secondary = "";
  if (timestamp) {
    toDisplay = day.format("HH:mm, MMMM DD");
    secondary = day.format("dddd");
  }
  if (when) toDisplay = when;

  return (
    <div className="EventIconRow">
      <div className="RowCenterCenter">
        <img src={SvgTime} alt="time" />
      </div>
      <p>
        {toDisplay}
        {secondary ? (
          <span
            style={{ color: "var(--color-gray)" }}
          >{` (${secondary})`}</span>
        ) : null}
      </p>
    </div>
  );
};

export const LocationWithIcon = ({ location }: { location?: string }) => {
  if (!location) return null;
  return (
    <div className="EventIconRow">
      <div className="RowCenterCenter">
        <img src={SvgPin} alt="location" />
      </div>
      <p>{location}</p>
    </div>
  );
};

export const validateInterval_byDateAndTime = (
  testDate: Dayjs,
  interval: string,
  eventType: EventType
) => {
  const hm = interval.split(":");
  const dateEvent = dayjs(testDate)
    .set("hour", Number(hm[0]))
    .set("minute", Number(hm[1]));
  const datePresent = dayjs();
  let compareBy: "minute" | "hour" =
    eventType.unableUnits === UNABLE_UNITS.MINUTES ? "minute" : "hour";
  const res: number = dateEvent.diff(datePresent, compareBy);

  return res < Number(eventType.unable);
};

export const isValidDayByRepeatSettings = (day: any) => {
  const eventType = day?.eventType;
  const repeat = eventType?.repeat;
  if (!repeat?.selection?.startDate) return false;

  const dayTimestamp = dayjs(day).valueOf();
  const startDate = repeat.selection.startDate;
  const endDate = repeat.selection?.endDate;

  let result = true;
  switch (repeat.type) {
    case REPEAT_TYPE.ONE_TIME:
      if (dayTimestamp === startDate) {
        result = true;
      } else {
        result = false;
      }

      break;
    case REPEAT_TYPE.INFINITE:
      if (dayTimestamp < startDate) {
        result = false;
      }

      break;
    case REPEAT_TYPE.RANGE:
      if (endDate && (dayTimestamp < startDate || dayTimestamp > endDate)) {
        result = false;
      }
      break;

    default:
      break;
  }
  return result;
};

export const changeShow_updateRepeat = (idx: number) => {
  const timestamp_endOfToday = dayjs()
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0)
    .valueOf();

  const selection = {
    key: "selection", // not really used for anything. specific to DateRange
    startDate: timestamp_endOfToday,
    endDate: timestamp_endOfToday,
  };
  let result: Repeat = { type: idx, selection };
  return result;
};

export const formatDateSelection_ToLabel = (
  show: number,
  selection?: RepeatDateSelection
) => {
  if (!selection || !selection.startDate) {
    return dayjs().format(DATE_FORMAT_dddd_DD_MMMM_YYYY);
  }

  const start = dayjs(selection.startDate);
  if (selection.startDate === selection.endDate || show !== 2) {
    return start.format(DATE_FORMAT_dddd_DD_MMMM_YYYY);
  }

  const end = dayjs(selection.endDate);
  const year = start.format("YYYY");
  let result = "";

  if (year === end.format("YYYY")) {
    result = `${start.format(DATE_FORMAT_DD_MMMM)} - ${end.format(
      DATE_FORMAT_DD_MMMM
    )} ${year}`;
  } else {
    result = `${start.format(DATE_FORMAT_DD_MMMM_YYYY)} - ${end.format(
      DATE_FORMAT_DD_MMMM_YYYY
    )}`;
  }

  return result;
};
