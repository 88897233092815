import React from "react";
import styles from "../layout.module.css";
import ExplanationSection from "./section-explanation/ExplanationSection";
import Footer from "./Footer";

interface MaskProps {
  children: React.ReactNode;
  refScroll: React.RefObject<HTMLInputElement>;
}

function Mask({ children, refScroll }: MaskProps) {
  return (
    <div className={styles.Desktop_LandingSection_App}>
      <div ref={refScroll} className={styles.Desktop_Body}>
        <div className={styles.Desktop_MobileApp_Outer}>
          <div className={styles.Desktop_MobileApp_Inner}>{children}</div>
        </div>

        <div className={styles.DesktopExplanationsOuter}>
          <ExplanationSection />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Mask;
