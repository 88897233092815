import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { PATHS } from "../../../../firebase/types";
import styles from "./explanation.module.css";
import Svg from "../../../../assets/svg/stats.svg";
import SvgOn from "../../../../assets/svg/stats_on.svg";

function Info_Stats() {
  const { pathname } = useLocation();
  const [hover, setHover] = useState(false);
  const toggleHover = () => setHover((state) => !state);

  if (pathname !== `/${PATHS.STATS}`) return null;

  return (
    <>
      <div className={styles.LineTitle}>
        <img
          src={hover ? Svg : SvgOn}
          alt="Statistics"
          onMouseOver={toggleHover}
          onMouseOut={toggleHover}
        />
        <p className="Title_Bigger">Statistics</p>
      </div>
      <p>Statistics section details</p>
    </>
  );
}

export default Info_Stats;
