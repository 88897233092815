import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import styles from "./event-type.module.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  DateRangeSelection,
  REPEAT_TYPE,
  Repeat,
  RepeatDateSelection,
} from "../../../firebase/types";
import OpenRangePicker from "../../range-picker/OpenRangePicker";
import { motion } from "framer-motion";
import DateRangeCalendar from "../../range-picker/Calendar";
import {
  changeShow_updateRepeat,
  formatDateSelection_ToLabel,
} from "../../../firebase/general";

interface RepeatEventProps {
  data: Repeat | null;
}

export const RepeatEvent = forwardRef(({ data }: RepeatEventProps, ref) => {
  const [show, setShow] = useState(0);
  const [showRangePicker, setShowRangePicker] = useState(false);
  const [repeat, setRepeat] = useState<Repeat>({ type: REPEAT_TYPE.ONE_TIME });

  useEffect(() => {
    if (!data) return;
    setRepeat(data);
    setShow(data.type);
  }, [data]);

  useImperativeHandle(ref, () => ({
    getRepeat: () => repeat,
  }));

  const labels = useMemo(
    () => [
      { label: "Indefinitely, starting from", value: REPEAT_TYPE.INFINITE },
      { label: "This is a one time event", value: REPEAT_TYPE.ONE_TIME },
      { label: "Within a date range", value: REPEAT_TYPE.RANGE },
    ],
    []
  );
  const inputValue = formatDateSelection_ToLabel(show, repeat?.selection);
  const toggleRangePicker = () => setShowRangePicker((state) => !state);
  const radioBtnClicked = (idx: number) => {
    setShow(idx);
    setRepeat(changeShow_updateRepeat(idx));
    setShowRangePicker(false);
  };

  const onChange_DateRange = (data: DateRangeSelection) => {
    const { key, startDate, endDate } = data;
    const selection: RepeatDateSelection = {
      key,
      startDate: Date.parse(startDate),
      endDate: Date.parse(endDate),
    };
    setRepeat({ type: show, selection });
  };
  const onChange_DateCalendar = (item: string) => {
    const selection: RepeatDateSelection = {
      key: "selection",
      startDate: Date.parse(item),
      endDate: 0,
    };
    setRepeat({ type: show, selection });
  };

  return (
    <div>
      <p style={{ margin: "12px 0 5px 12px", fontSize: "var(--fs-small)" }}>
        Repeat event
      </p>

      {labels.map(
        (item: { label: string; value: REPEAT_TYPE }, idx: number) => (
          <div
            className={styles.RepeatListElement}
            onClick={() => radioBtnClicked(idx)}
            key={idx}
          >
            <input
              type="radio"
              className="Radio"
              id={`repeat_${item.value}`}
              name={`repeat_${item.value}`}
              checked={show === item.value ? true : false}
              onChange={() => {}}
            />
            <label htmlFor={`repeat_${item.value}`}>
              <span>{item.label}</span>
            </label>
          </div>
        )
      )}

      <div className={styles.DummyInput} onClick={toggleRangePicker}>
        {inputValue}
      </div>

      {showRangePicker && show !== 2 ? (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <div className="TextAlignCenter">
            <DateRangeCalendar
              onChange={onChange_DateCalendar}
              receivedDate={repeat.selection?.startDate}
            />
          </div>
          <p className="Button" onClick={toggleRangePicker}>
            Done. Close range selector.
          </p>
        </motion.div>
      ) : null}

      {showRangePicker && show === 2 ? (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <div className="TextAlignCenter">
            <OpenRangePicker
              selectRange={onChange_DateRange}
              selection={repeat.selection}
            />
          </div>
          <p className="Button" onClick={toggleRangePicker}>
            Done. Close range selector.
          </p>
        </motion.div>
      ) : null}
    </div>
  );
});
