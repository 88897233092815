import React from "react";
import classes from "./user.module.css";
import { useAppContext } from "../../store/AppContext";

function MainInfo() {
  const { currentUser } = useAppContext();

  return (
    <div className={classes.MainInfoOuter}>
      <p className="Title_Big" style={{ padding: "0" }}>
        {currentUser?.displayName ? currentUser?.displayName : "name"}
      </p>
      <p>{currentUser?.email ? currentUser?.email : "email"}</p>
    </div>
  );
}

export default MainInfo;
