import React from "react";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
  const navigate = useNavigate();

  const goToHomepage = () => navigate("/");

  const goBack = () => navigate(-1);

  return (
    <div>
      <br />
      <br />
      <br />
      <p className="Title TextAlignCenter">Page Not Found</p>
      <br />

      <div className="Grid2Columns">
        <p className="Button" onClick={goToHomepage}>
          Home page
        </p>

        <p className="Button Secondary" onClick={goBack}>
          Go back
        </p>
      </div>
    </div>
  );
}

export default PageNotFound;
