import React, { useContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import {
  Anamneza,
  Event,
  EventType,
  LocalStorageItems,
  Participant,
  USER_ROLE,
  User,
  Microcycle,
  PLAN_ID_DEFAULT,
  MicrocycleClient,
  Workout,
  MicrocycleEventType,
  EarlyAccess,
} from "../firebase/types";
import { auth } from "../firebase/config";
import { updateCachedItem } from "./local-storage";
import {
  createEvent,
  createEventType,
  createUser,
  deleteEventType,
  getEventTypesByProUID,
  getEventByCreatorUIDAndTimestamp,
  getEventsByClientUID,
  getEventsByProUID,
  getUserByUID,
  getUserByHandle,
  getUsersByHandle,
  updateEventType,
  updateUser,
  getEventTypesByLink,
  updateEvent,
  getEventByID,
  getRecentEventTypes,
  createMicrocycle,
  updateMicrocycle,
  getMicrocyclesByProUID,
  getMicrocycleByID,
  deleteEvent,
  deleteMicrocycle,
  getUsersByDisplayName,
  createAnamneza,
  getAnamnezaByUID,
  updateAnamneza,
  createEarlyAccess,
  getEarlyAccessByEmail,
} from "../firebase/firestore-database";
import { getUserNameOrEmail } from "../firebase/general";

interface AppContextType {
  isMobile: boolean;
  modal: JSX.Element | undefined;
  setModal: any;
  //
  byCity: string;
  byCountry: string;
  set_search_by_city: (val: string) => void;
  set_search_by_country: (val: string) => void;
  //
  currentUser: User | null; // TODO: User | null
  anamneza: Anamneza | null;
  proUser: User | null;
  eventTypes: EventType[] | null;
  events: Event[] | null;
  microcycles: Microcycle[] | null;

  // CREATE
  create_user: (request: User) => Promise<void>;
  create_anamneza: (request: Anamneza) => Promise<void>;
  // create_event_type: (request: EventType) => void;
  create_event: (request: Event) => Promise<void>;
  create_early_access: (request: EarlyAccess) => Promise<void>;

  // UPDATE
  update_user: (request: User) => Promise<void>;
  update_event_type: (request: EventType) => Promise<void>;
  update_event: (request: Event) => Promise<void>;
  update_anamneza: (request: Anamneza) => Promise<void>;

  // DELETE
  delete_event_type: (id: string) => Promise<void>;
  delete_appointment: (id: string) => Promise<void>;
  delete_microcycle: (id: string) => Promise<void>;

  // GET
  get_user_by_uid: (uid: string) => any;
  get_user_by_handle: (handle: string) => Promise<User | null>;
  get_users_by_handle: (handle: string) => Promise<User[] | null>;
  get_users_by_display_name: (search: string) => Promise<User[] | null>;
  get_event_types: (uid?: string) => any;
  get_events: (user?: User) => Promise<void>;
  get_event_details: (uid: string, id: string) => Promise<Event | null>;
  get_pro_and_event_type: (handle: string, link: string) => void;
  get_pro_and_event_types: (handle: string) => void;
  get_recent_event_types: () => Promise<EventType[] | null>;
  get_microcycles_for_creator: (uid: string) => Promise<void>;
  get_microcycle_by_id: (id: string) => Promise<Microcycle | null>;

  // OTHER
  search_pro_users: (handle: string) => Promise<User[] | null>;
  client_schedules_event: (request: Event) => any;
  cancel_appointment: (request: Event, uid: string) => any;
  save_microcycle: (request: Microcycle) => any;

  // SELECTED
  selectedDay: any;
  set_selected_day: (day?: any) => void;
}

const AppContext = React.createContext<AppContextType>(null!);

export function useAppContext() {
  return useContext(AppContext);
}

export function ContextProvider({
  window,
  children,
}: {
  window: any;
  children: React.ReactNode;
}) {
  const { localStorage } = window;
  const [isMobile, setIsMobile] = useState(false);
  const [modal, setModal] = useState<JSX.Element | undefined>();

  const [byCity, setByCity] = useState("");
  const [byCountry, setByCountry] = useState("");

  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [anamneza, setAnamneza] = useState<Anamneza | null>(null);
  const [proUser, setProUser] = useState<User | null>(null);
  const [eventTypes, setEventTypes] = useState<EventType[] | null>(null);
  const [events, setEvents] = useState<Event[] | null>(null);
  const [microcycles, setMicrocycles] = useState<Microcycle[] | null>(null);

  const [selectedDay, setSelectedDay] = useState<any>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        if (user) {
          (async () => {
            let user_from_db: User | null = await getUserByUID(user?.uid);

            if (user_from_db === null) {
              const request: User = {
                uid: user.uid,
                email: user.email ? user.email : "",
                displayName: user.displayName ? user.displayName : "",
                photoURL: user.photoURL,
                phoneNumber: user.phoneNumber ? user.phoneNumber : "",
                firstLogin: Date.now(),
                lastLogin: Date.now(),
                role: USER_ROLE.CLIENT,
                handle: "",
                about: "",
                city: "",
                country: "",
              };

              await createUser(request);
              await createAnamneza({
                uid: user.uid,
                objective: "",
                status: "",
                focus: "",
                history: "",
                critical: "",
                updatedAt: Date.now(),
              });
              user_from_db = await getUserByUID(user?.uid);
            }

            // TODO. poate trebuie un update_user aici
            if (user_from_db) {
              user_from_db.lastLogin = Date.now();
              setCurrentUser(user_from_db);

              const found = await getAnamnezaByUID(user_from_db.uid);
              if (found) setAnamneza(found);
            }
            if (user_from_db && user_from_db.role === USER_ROLE.PRO)
              // get microcycles. no need for await:
              get_microcycles_for_creator(user.uid);
          })();
        } else {
          setCurrentUser(user);
        }
        let user_email = user && user.email ? user.email : "-";
        updateCachedItem(user_email, LocalStorageItems.SIGNED_IN);
        // setByCity("");
        // setByCountry("");
      },
      (error) => {
        alert("error onAuthStateChanged: " + error);
      },
      () => {}
    );

    return () => {
      unsubscribe();
    };
  }, [localStorage]);

  useEffect(() => {
    const set_media = () => {
      let isMobile = window.matchMedia("(max-width: 900px)").matches;
      setIsMobile(isMobile);
    };

    window.addEventListener("resize", set_media);
    set_media();

    return () => {
      window.removeEventListener("resize", set_media);
    };
  }, [window]);

  const set_search_by_city = (val: string) => setByCity(val);
  const set_search_by_country = (val: string) => setByCountry(val);

  // CREATE
  const create_early_access = async (request: EarlyAccess) => {
    try {
      const record = await getEarlyAccessByEmail(request.email);
      console.log(record);
      if (record) {
        setModal(
          <div>
            <p className="Title" style={{ padding: "10px 0" }}>
              Thanks for joining!
            </p>
            <p style={{ paddingBottom: "10px" }}>
              Your email address (<b>{record.email}</b>) is already added to the
              waitlist.
            </p>
          </div>
        );
        return;
      }
      await createEarlyAccess(request);
      setModal(
        <>
          <p className="Title">You've been added to the waitlist!</p>
          <p style={{ padding: "10px 0" }}>
            We'll send you an email with any useful information.
          </p>
        </>
      );
    } catch (error) {
      console.log(error);
    }
  };
  const create_user = async (request: User) => {
    try {
      await createUser(request);
    } catch (error) {
      console.log(error);
    }
  };
  const create_anamneza = async (request: Anamneza) => {
    try {
      // const result: Anamneza =
      await createAnamneza(request);
      // console.log("create_anamneza", result);
    } catch (error) {
      console.log(error);
    }
  };
  // const create_event_type = async (request: EventType) => {
  //   try {
  //     const result: EventType = await createEventType(request);
  //     if (result) {
  //       console.log("create_event_type !", result);
  //       // updatePagesContextState(docData);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const create_event = async (request: Event) => {
    try {
      console.log("create_event !!! TODO", request);

      // const result: any = await createEvent(request);
      // if (result) {
      //   const docData: User = result;
      //   console.log(docData);
      //   const events_update = events ? [...events, request] : [request];
      //   setEvents(events_update);
      // }
    } catch (error) {
      console.log(error);
    }
  };

  // UPDATE
  const update_user = async (request: User) => {
    try {
      await updateUser(request);
      setCurrentUser(request);
    } catch (error) {
      console.log(error);
    }
  };
  const update_event_type = async (request: EventType) => {
    if (!request) return;
    try {
      if (request.id === "new") {
        delete request["id"];
        request.createdAt = Date.now();
        const result: EventType = await createEventType(request);
        if (result && result.id) {
          console.log("created EventType:", result);
        }
      } else {
        await updateEventType(request);
      }
    } catch (error) {
      console.log("update_event_type:", error);
    }
  };
  const update_event = async (request: Event) => {
    try {
      await updateEvent(request);
    } catch (error) {
      console.log(error);
    }
  };
  const cancel_appointment = async (request: Event, uid: string) => {
    try {
      if (!currentUser) return;

      if (currentUser.uid === request.creatorUID) {
        // canceled by creator:
        request.canceledByCreator = Date.now();
        request.attendeeUIDs = [];
        request.attendees = [];
        await updateEvent(request);
        return;
      }

      // client is canceling:
      request.attendeeUIDs = request.attendeeUIDs?.filter(
        (item: string) => item !== uid
      );
      request.attendees = request.attendees?.filter(
        (item: Participant) => item.uid !== uid
      );
      const toAdd = {
        name: getUserNameOrEmail(currentUser),
        uid: currentUser.uid,
        date: Date.now(),
      };
      if (request.canceled && request.canceled.length) {
        const found = request.canceled?.find(
          (item: Participant) => item.uid === currentUser.uid
        );
        if (!found) request.canceled.push(toAdd);
      } else {
        request.canceled = [toAdd];
      }

      await updateEvent(request);
    } catch (error) {
      console.log(error);
    }
  };
  const update_anamneza = async (request: Anamneza) => {
    try {
      await updateAnamneza(request);
      setAnamneza(request);
    } catch (error) {
      console.log(error);
    }
  };

  // DELETE
  const delete_event_type = async (id: string) => {
    try {
      await deleteEventType(id);
      if (!eventTypes) return;
      setEventTypes(eventTypes.filter((ev) => ev.id !== id));
    } catch (error) {}
  };
  const delete_appointment = async (id: string) => {
    try {
      await deleteEvent(id);
    } catch (error) {
      console.log(error);
    }
  };
  const delete_microcycle = async (id: string) => {
    try {
      await deleteMicrocycle(id);
      if (!microcycles) return;
      setMicrocycles(microcycles?.filter((item) => item.id !== id));
    } catch (error) {
      console.log(error);
    }
  };

  // GET
  const get_user_by_uid = async (uid: string) => {};
  const get_user_by_handle = async (handle: string) => {
    try {
      const user: User | null = await getUserByHandle(handle);
      return user;
    } catch (error) {
      console.log(error);
      // show modal with error
      return null;
    }
  };
  const get_users_by_handle = async (handle: string) => {
    try {
      const result: User[] | null = await getUsersByHandle(handle);
      return result;
    } catch (error) {
      console.log(error);
      // show modal with error
      return null;
    }
  };
  const get_users_by_display_name = async (search: string) => {
    try {
      const result: User[] | null = await getUsersByDisplayName(search);
      return result;
    } catch (error) {
      console.log(error);
      // show modal with error
      return null;
    }
  };
  const get_pro_and_event_type = async (handle: string, link: string) => {
    try {
      const user: User | null = await getUserByHandle(handle);
      if (user === null) return;

      const from_db: EventType[] | null = await getEventTypesByLink(link);
      setEventTypes(from_db);
      setProUser(user);
    } catch (error) {
      console.log(error);
      // show modal with error
      return null;
    }
  };
  const get_pro_and_event_types = async (handle: string) => {
    try {
      const user: User | null = await getUserByHandle(handle);
      if (user === null) return;

      const from_db: EventType[] | null = await getEventTypesByProUID(user.uid);
      setEventTypes(from_db);
      setProUser(user);
    } catch (error) {
      console.log(error);
      // show modal with error
      return null;
    }
  };
  const get_recent_event_types = async () => {
    try {
      const evTypes: EventType[] | null = await getRecentEventTypes();
      return evTypes;
    } catch (error) {
      console.log(error);
      // show modal with error
      return null;
    }
  };
  const get_microcycles_for_creator = async (uid: string) => {
    try {
      const mcs: Microcycle[] | null = await getMicrocyclesByProUID(uid);
      setMicrocycles(mcs);
      // return mcs;
    } catch (error) {
      console.log(error);
      // show modal with error
      // return null;
    }
  };

  // SPECIAL

  const search_pro_users = async (handle: string) => {
    try {
      const users: User[] | null = await getUsersByHandle(handle);
      return users;
    } catch (error) {
      console.log(error);
      // show modal with error
      return null;
    }
  };

  const update_microcycle_when_schedule_event = async (
    request: Event,
    event: Event | null
  ) => {
    if (!currentUser || request.canceledByCreator || !request.id) return;

    const check_plan = request.planID === PLAN_ID_DEFAULT;
    const specificPlanID = request.planID && request.planID !== PLAN_ID_DEFAULT;

    let foundPlan: Microcycle | undefined | null = undefined;
    let workout: Workout | null = null;

    if (check_plan) {
      // check plan from plans by client uid
      const plans = await getMicrocyclesByProUID(request.creatorUID);
      foundPlan = plans?.find((plan: Microcycle) =>
        plan.clients.find(
          (client: MicrocycleClient) => client.uid === currentUser.uid
        )
      );
      if (!foundPlan || !foundPlan.workouts) return null;
      let foundClient = foundPlan?.clients.find(
        (client: MicrocycleClient) => client.uid === currentUser.uid
      );
      // console.log(foundPlan);
      // console.log(foundClient);

      if (foundClient) {
        workout =
          foundPlan.workouts[foundClient.index % foundPlan.workouts.length];

        const updatedClients = foundPlan.clients.map(
          (client: MicrocycleClient) =>
            client.uid === currentUser.uid
              ? { ...client, index: client.index + 1 }
              : client
        );
        // console.log(updatedClients);
        await updateMicrocycle({ ...foundPlan, clients: updatedClients });
      }
    }

    if (specificPlanID) {
      // if this is a class (multiple clients), create the
      // workout ONLY when creating the event!
      if (event?.workout) return event.workout;

      // check plan from plans by client uid
      foundPlan = await getMicrocycleByID(request.planID);
      if (!foundPlan || !foundPlan.workouts) return null;
      let foundEventType = foundPlan?.event_types.find(
        (item: MicrocycleEventType) => item.id === request.id
      );
      // console.log(foundEventType);
      let updatedEvent_types: MicrocycleEventType[] = [];

      if (foundEventType) {
        // UPDATE:
        workout =
          foundPlan.workouts[foundEventType.index % foundPlan.workouts.length];

        updatedEvent_types = foundPlan.event_types.map(
          (item: MicrocycleEventType) =>
            item.id === request.id ? { ...item, index: item.index + 1 } : item
        );
      } else {
        // CREATE NEW:
        workout = foundPlan.workouts[0];

        updatedEvent_types = [{ id: request.id, name: request.name, index: 1 }];
      }
      // console.log(updatedEvent_types);
      await updateMicrocycle({
        ...foundPlan,
        event_types: updatedEvent_types,
      });
    }

    return workout;
  };
  const client_schedules_event = async (request: Event) => {
    try {
      if (!currentUser || request.canceledByCreator) return;
      const toAddAttendees = {
        name: getUserNameOrEmail(currentUser),
        uid: currentUser.uid,
        date: Date.now(),
      };
      delete request["week"];

      let event: Event | null = await getEventByCreatorUIDAndTimestamp(
        request.timestamp,
        request.creatorUID
      );

      if (event && Number(event.maxAttendees) === event.attendees?.length) {
        setModal(
          <>
            <p>This event reached it's maximum number of participants.</p>
            <p style={{ paddingTop: "10px" }}>
              Contact the host to check if you can join this event.
            </p>
          </>
        );
        // ALREADY JOINED -> DO NOTHING
        return false;
      }

      const workout = await update_microcycle_when_schedule_event(
        request,
        event
      );
      if (workout) request.workout = workout;

      if (event && event.attendeeUIDs) {
        const existing = event.attendeeUIDs.find(
          (item: string) => item === currentUser.uid
        );
        if (existing) {
          setModal(
            <p className="TextSecondary">
              You are already signed up for this event at this hour
            </p>
          );
          // ALREADY JOINED -> DO NOTHING
          return false;
        }
        request.attendeeUIDs = [...event.attendeeUIDs, currentUser.uid];
        if (event.attendees) {
          request.attendees = [...event.attendees, toAddAttendees];
        }

        request.id = event.id;
        await updateEvent(request);
      } else {
        // CREATE new event:
        request.attendeeUIDs = [currentUser.uid];
        request.attendees = [toAddAttendees];
        request.canceled = [];
        delete request["id"];
        await createEvent(request);
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  const save_microcycle = async (request: Microcycle) => {
    if (!currentUser) return;
    try {
      const today = Date.now();
      request.updatedAt = today;

      if (request.id === "new") {
        delete request["id"];
        request.createdAt = today;
        let workout: Microcycle | null = await createMicrocycle(request);
        return workout;
      } else {
        await updateMicrocycle(request);
        return request;
      }
    } catch (error) {
      console.log("Save workout", error);
    }
  };

  const set_selected_day = (day?: any) => {
    setSelectedDay(day);
  };

  const get_event_details = async (uid: string, id: string) => {
    try {
      const result: Event | null = await getEventByID(id);
      if (!result) return null;

      const creator: User | null = await getUserByUID(result.creatorUID);
      setProUser(creator);

      return result;
    } catch (error) {
      console.log("error", error);
      return null;
    }
  };
  const get_events = async (user?: User) => {
    try {
      let _user = user ? user : currentUser;

      if (!_user) return;
      const forClient: Event[] | null = await getEventsByClientUID(_user.uid);
      let forPro: Event[] | null = null;

      let result: Event[] = [];

      if (_user.role === USER_ROLE.PRO) {
        forPro = await getEventsByProUID(_user.uid);
      }

      if (forClient) result = forClient;
      if (forPro) result = [...result, ...forPro];

      setEvents(result);
    } catch (error) {
      console.log("Get events error", error);
    }
  };
  // const get_events = async (user?: User) => {
  //   try {
  //     let _user = user ? user : currentUser;

  //     if (!_user) return;
  //     let result: Event[] | null = null;

  //     if (_user.role === USER_ROLE.CLIENT) {
  //       result = await getEventsByClientUID(_user.uid);
  //     }

  //     if (_user.role === USER_ROLE.PRO) {
  //       result = await getEventsByProUID(_user.uid);
  //     }

  //     setEvents(result);
  //   } catch (error) {
  //     console.log("Get events error", error);
  //   }
  // };
  const get_event_types = async (uid?: string) => {
    try {
      let id = currentUser && currentUser.uid ? currentUser.uid : "";
      if (uid) id = uid;

      const from_db: EventType[] | null = await getEventTypesByProUID(id);
      setEventTypes(from_db);
    } catch (error) {
      console.log("get_event_types", error);
    }
  };
  const get_microcycle_by_id = async (id: string) => {
    try {
      const result: Microcycle | null = await getMicrocycleByID(id);
      return result;
    } catch (error) {
      console.log("get microcycle by id", error);
      return null;
    }
  };

  let value = {
    isMobile,
    modal,
    setModal,
    //
    byCity,
    byCountry,
    set_search_by_city,
    set_search_by_country,
    //
    proUser,
    currentUser,
    anamneza,
    eventTypes,
    events,
    microcycles,

    // CREATE
    create_user,
    create_anamneza,
    // create_event_type,
    create_event,
    create_early_access,

    // UPDATE
    update_user,
    update_event_type,
    update_event,
    update_anamneza,

    // DELETE
    delete_event_type,
    delete_appointment,
    delete_microcycle,

    // GET
    get_user_by_uid,
    get_user_by_handle,
    get_users_by_handle,
    get_users_by_display_name,
    get_event_types,
    get_events,
    get_event_details,
    search_pro_users,
    get_pro_and_event_type,
    get_pro_and_event_types,
    get_recent_event_types,
    get_microcycles_for_creator,
    get_microcycle_by_id,

    // SELECT
    selectedDay,
    set_selected_day,

    // OTHER
    client_schedules_event,
    cancel_appointment,
    save_microcycle,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

// People who say it cannot be done, should not interrupt those who are doing.
