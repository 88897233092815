import React from "react";
import styles from "./save-cancel.module.css";
import { motion } from "framer-motion";
import { useAppContext } from "../../store/AppContext";

interface SaveCancelProps {
  save: any;
  cancel: any;
  fixedTop?: boolean;
}

function SaveCancel({ save, cancel, fixedTop = false }: SaveCancelProps) {
  const { isMobile } = useAppContext();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.Outer}
    >
      <div
        className={fixedTop && isMobile ? styles.FixedTop : styles.RegularSplit}
      >
        <button className={`${styles.Button} ${styles.Save}`} onClick={save}>
          Save
        </button>
        <button
          className={`${styles.Button} ${styles.Cancel}`}
          onClick={cancel}
        >
          Cancel
        </button>
      </div>
    </motion.div>
  );
}

export default SaveCancel;
