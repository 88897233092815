import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../firebase/types";
import { useAppContext } from "../../../store/AppContext";
import EditHandle from "./EditHandle";
import CopySvg from "../../../assets/svg/copy.svg";
import styles from "./handle.module.css";
import { copyToClipboard } from "../../../firebase/general";

function Handle() {
  const { currentUser, setModal } = useAppContext();
  const navigate = useNavigate();
  const [showEdit, setShowEdit] = useState(false);

  if (!currentUser || currentUser.role !== "pro") return null;

  const handle = currentUser.handle ? currentUser.handle : "";

  const openUserHandle = () => navigate(`/${PATHS.PRO}/${handle}`);
  const openEdit = () => setShowEdit(true);

  const copy_to_clipboard = () => {
    copyToClipboard(`${PATHS.BASE}/${PATHS.PRO}/${handle}`);
    showModal();
  };
  const showModal = () => {
    const modalContent = (
      <>
        <p>User handle copied!</p>
        <p className="TextSecondary">Now you can paste it where you want to</p>
      </>
    );
    setModal(modalContent);
  };

  return (
    <div className={styles.HandleOuter}>
      <p>Link to your own page where clients can see your Event Types:</p>
      {handle && (
        <p
          className="Link"
          onClick={openUserHandle}
          style={{ padding: "6px 0 3px 0" }}
        >
          {`${PATHS.BASE}/${PATHS.PRO}/${handle}`}
        </p>
      )}

      {showEdit ? (
        <EditHandle hideEditHandle={() => setShowEdit(false)} />
      ) : (
        <div className={styles.RowEndsWithCopyBtn}>
          <p className="Button" onClick={openEdit}>
            {handle ? "Edit" : "Set up"} your handle
          </p>
          <img
            src={CopySvg}
            alt="copy"
            className={styles.IconCopy}
            onClick={copy_to_clipboard}
          />
        </div>
      )}
    </div>
  );
}

export default Handle;
