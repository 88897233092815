import React, { useState } from "react";
import { useAppContext } from "../../../store/AppContext";
import EditAbout from "./EditAbout";
import styles from "./about.module.css";

function About() {
  const { currentUser } = useAppContext();
  const [showEdit, setShowEdit] = useState(false);
  const toggleShow = () => setShowEdit((state) => !state);

  if (!currentUser) return null;

  return (
    <>
      {showEdit ? (
        <EditAbout user={currentUser} closeEdit={toggleShow} />
      ) : (
        <>
          <p className={styles.Label}>About</p>
          <p>{currentUser?.about}</p>
          <p className={styles.Label}>Phone number</p>
          <p>{currentUser?.phoneNumber}</p>
          <p className={styles.Label}>City</p>
          <p>{currentUser?.city}</p>
          <p className={styles.Label}>Country</p>
          <p>{currentUser?.country}</p>
          <p className="Button" onClick={toggleShow}>
            Edit personal details
          </p>
        </>
      )}
    </>
  );
}

export default About;
