import React from "react";
import { useAppContext } from "../../store/AppContext";
import { PATHS, USER_ROLE } from "../../firebase/types";
import { useNavigate } from "react-router-dom";

function ButtonUpgradeAccount() {
  const { currentUser } = useAppContext();
  const navigate = useNavigate();

  if (!currentUser || currentUser.role === USER_ROLE.PRO) return null;

  return (
    <>
      <br />
      <p className="TextAlignCenter">
        Upgrade your account to host your own events
      </p>
      <p className="Button Black" onClick={() => navigate(`/${PATHS.BILLING}`)}>
        Upgrade account
      </p>
    </>
  );
}

export default ButtonUpgradeAccount;
