import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import classes from "./event-type.module.css";
import SvgTrash from "../../../assets/svg/trash_can.svg";
import SvgPlus from "../../../assets/svg/plus.svg";
import { HoursType, Weekday } from "../../../firebase/types";
import {
  DEFAULT_HOURS,
  DEFAULT_WEEK,
  makeHourStructure,
} from "../../../firebase/general";

const hourIntervals = makeHourStructure();

export interface refAvailability {
  getWeek: () => string | null;
}

export const Availability = forwardRef(
  ({ data }: { data?: Weekday[] | null }, ref) => {
    const [week, setWeek] = useState<Weekday[]>(DEFAULT_WEEK);

    useEffect(() => {
      if (!data) return;
      setWeek(data);
    }, [data]);

    useImperativeHandle(ref, () => ({
      getWeek: () => week,
    }));

    const becomeAvailable = (day_index: number) => {
      setWeek(
        week.map((_day, _idx) =>
          _idx === day_index ? { ..._day, hours: [DEFAULT_HOURS] } : _day
        )
      );
    };
    const becomeUnavailable = (day_index: number) => {
      setWeek(
        week.map((_day, _idx) =>
          _idx === day_index ? { ..._day, hours: [] } : _day
        )
      );
    };
    const toggleAvailability = (day_index: number) => {
      if (week[day_index].hours.length) {
        becomeUnavailable(day_index);
      } else {
        becomeAvailable(day_index);
      }
    };

    const removeHours = (day_index: number, hour_index: number) => {
      const to_update = week.map((day, idx) => {
        if (idx === day_index) {
          return {
            ...day,
            hours: day.hours.filter((h, hIdx) => hIdx !== hour_index),
          };
        } else return day;
      });
      setWeek(to_update);
    };

    const addHours = (day_index: number) => {
      const to_update = week.map((day, idx) => {
        if (idx === day_index) {
          return {
            ...day,
            hours: [...day.hours, DEFAULT_HOURS],
          };
        } else return day;
      });
      setWeek(to_update);
    };

    const selectHours = (
      ev: React.ChangeEvent<HTMLSelectElement>,
      day_index: number,
      hour_index: number,
      type: string
    ) => {
      const to_update = week.map((day, idx) => {
        if (idx === day_index) {
          return {
            ...day,
            hours: day.hours.map((h, hIdx) =>
              hIdx === hour_index ? { ...h, [type]: ev.target.value } : h
            ),
          };
        } else return day;
      });
      setWeek(to_update);
    };

    const HourStructure = ({
      day_index,
      hour,
      hour_index,
    }: {
      day_index: number;
      hour: HoursType;
      hour_index: number;
    }) => {
      return (
        <div className={classes.HoursLine}>
          <select
            value={hour.start}
            onChange={(ev) => selectHours(ev, day_index, hour_index, "start")}
          >
            {hourIntervals.map((h, idx) => (
              <option key={idx}>{h}</option>
            ))}
          </select>

          <p style={{ textAlign: "center", color: "var(--color-gray)" }}>-</p>
          <select
            value={hour.end}
            onChange={(ev) => selectHours(ev, day_index, hour_index, "end")}
          >
            {hourIntervals.map((h, idx) => (
              <option key={idx}>{h}</option>
            ))}
          </select>

          <img
            src={SvgTrash}
            alt="rem"
            className={classes.SvgTrash}
            onClick={() => removeHours(day_index, hour_index)}
          />
        </div>
      );
    };

    return (
      <table className={classes.TableSchedule}>
        <tbody>
          {week.map((day, day_index) => (
            <tr key={day_index}>
              <td onClick={() => toggleAvailability(day_index)}>
                <div>
                  <input
                    type="checkbox"
                    onChange={() => {}}
                    checked={day.hours.length > 0}
                    style={{ width: "25px", margin: "0" }}
                  />
                  <label>
                    <span>{day.id}</span>
                  </label>
                </div>
              </td>

              {day.hours.length ? (
                <td>
                  {day.hours.map((hour, hour_index) => (
                    <HourStructure
                      hour={hour}
                      day_index={day_index}
                      hour_index={hour_index}
                      key={hour_index}
                    />
                  ))}
                </td>
              ) : (
                <td
                  style={{
                    color: "var(--color-gray)",
                    fontSize: "var(--fs-small)",
                    width: "100%",
                  }}
                >
                  unavailable
                </td>
              )}

              <td>
                <img
                  src={SvgPlus}
                  alt="add"
                  className={classes.SvgPlus}
                  onClick={() => addHours(day_index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
);

export default Availability;
