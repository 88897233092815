import React from "react";
import styles from "./event-type.module.css";
import CopySvg from "../../../assets/svg/copy.svg";
import { copyToClipboard } from "../../../firebase/general";
import { Event, Microcycle, PATHS } from "../../../firebase/types";
import { useAppContext } from "../../../store/AppContext";
import { useNavigate } from "react-router-dom";

function EventTypeListElement({ event }: { event: Event }) {
  const { currentUser, setModal, microcycles } = useAppContext();
  const navigate = useNavigate();

  if (!currentUser) return null;

  const openPreview = (ev: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    ev.stopPropagation();
    navigate(`/${PATHS.PRO}/${currentUser.handle}/${event.link}`);
  };

  const copy_to_clipboard = (
    ev: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    ev.stopPropagation();
    if (event?.link && currentUser?.handle) {
      copyToClipboard(
        `${PATHS.BASE}/${PATHS.PRO}/${currentUser.handle}/${event.link}`
      );
      showModal();
    }
  };
  const editEventType = () =>
    navigate(`/${PATHS.USER}/${PATHS.EDIT_EVENT_TYPE}/${event.id}`);

  const showModal = () => {
    const modalContent = (
      <>
        <p>Event link copied!</p>
        <p className="TextSecondary">Now you can paste it where you want to</p>
      </>
    );
    setModal(modalContent);
  };
  const hasAssignedPlan =
    microcycles &&
    microcycles.find((plan: Microcycle) => plan.id === event.planID);

  return (
    <li className="EventListElement" onClick={editEventType}>
      <div className="TextAlignCenter">
        <p className="Title_Big">{event?.name}</p>
        <p>{event.shortDescription}</p>

        {/* <LocationWithIcon location={event.location} />
      <DurationWithIcon when={eventTypeDuration(event)} /> */}

        {hasAssignedPlan && (
          <p>
            <span className="Text_Gray">{`Training plan: `}</span>
            {hasAssignedPlan.name}
          </p>
        )}
      </div>

      <div className={styles.EventTypeListElement_Line_Links}>
        <span onClick={openPreview} className={styles.EventTypeLink}>
          Preview event type
        </span>

        <div className={styles.EventTypeCopyOuter} onClick={copy_to_clipboard}>
          <span className={styles.EventTypeLink}>copy link</span>
          <img src={CopySvg} alt="copy" className={styles.IconCopy} />
        </div>
      </div>
    </li>
  );
}

export default EventTypeListElement;
