import React from "react";
import { Event, PATHS, USER_ROLE } from "../../firebase/types";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../store/AppContext";
import {
  DurationWithIcon,
  ClientCanceled,
  LocationWithIcon,
  HostCanceled,
} from "../../firebase/general";
import styles from "./today.module.css";

function ListElementEvents({ event }: { event: Event }) {
  const navigate = useNavigate();
  const { currentUser } = useAppContext();

  const openEvent = () => navigate(`/${PATHS.EVENTS}/${event.id}`);

  const youAreHost =
    currentUser?.role === USER_ROLE.PRO && currentUser.uid === event.creatorUID;

  if (!currentUser) return null;

  return (
    <motion.li
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="EventListElement"
      onClick={openEvent}
    >
      <p className={styles.LabelName}>
        <span className="Title">{event.name}</span>
        {youAreHost && <span className={styles.LabelHost}>{` (HOST)`}</span>}
      </p>

      <HostCanceled event={event} />
      <ClientCanceled event={event} />
      <LocationWithIcon location={event.location} />
      <DurationWithIcon timestamp={event.timestamp} />
    </motion.li>
  );
}

export default ListElementEvents;
