import React, { useMemo } from "react";
import styles from "./user.module.css";
import { useAppContext } from "../../store/AppContext";
import SvgUser from "../../assets/svg/user_gray.svg";

function ProfilePhoto() {
  const { currentUser } = useAppContext();

  const photo = useMemo(
    () =>
      currentUser?.photoURL && currentUser.photoURL !== ""
        ? currentUser.photoURL
        : SvgUser,
    [currentUser]
  );

  if (!currentUser) return null;

  return (
    <div className={styles.ProfilePhoto}>
      <img className={styles.Image} src={photo} alt="profile" />
    </div>
  );
}

export default ProfilePhoto;
