import React, { useRef } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./footer/Footer";
import styles from "./layout.module.css";
import { motion } from "framer-motion";
// import NoInternetConnection from "../NoInternetConnection";
import Modal from "../modal/Modal";
import DesktopMaskSectionMotto from "./desktop-mask/section-motto/Landing";
import DesktopMask from "./desktop-mask/Mask";
import ScrollTop from "./ScrollTop";

function Layout() {
  const refScroll: React.RefObject<HTMLInputElement> = useRef(null);

  const scrollDown = () => {
    if (!refScroll?.current) return;
    refScroll?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.Outer}
    >
      <ScrollTop />
      <DesktopMaskSectionMotto scrollDown={scrollDown} />

      <DesktopMask refScroll={refScroll}>
        {/* START - THE WEB APP: */}
        {/* <Header /> */}

        <div className={styles.Content}>
          <Outlet />
        </div>

        <Footer />
        <Modal />
        {/* END - THE WEB APP: */}
      </DesktopMask>
    </motion.div>
  );
}

export default Layout;
