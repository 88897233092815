import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../store/AppContext";
import SaveCancel from "../../btns-save-cancel/SaveCancel";

function EditHandle({ hideEditHandle }: { hideEditHandle: () => void }) {
  const { currentUser, update_user } = useAppContext();
  const [handle, setHandle] = useState("");
  const [handleError, setHandleError] = useState("");

  useEffect(() => {
    if (currentUser?.handle) setHandle(currentUser.handle);
  }, [currentUser]);

  if (!currentUser) return null;

  const isTaken = async () => {
    // test unique handle in users collection
    // if not unique, setHandleError( err msg )
    return false;
  };

  const save = async () => {
    // if(isTaken()) return
    await update_user({ ...currentUser, handle });
    hideEditHandle();
  };

  return (
    <>
      <SaveCancel save={save} cancel={hideEditHandle} />

      <label>
        <span>Edit your unique Handle</span>
        <input
          type="textarea"
          placeholder="text-text"
          value={handle}
          onChange={(ev) => setHandle(ev.target.value)}
        />
      </label>
      {handleError ? <p className="ErrorParagraph">{handleError}</p> : <br />}
    </>
  );
}

export default EditHandle;
