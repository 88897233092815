import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../store/AppContext";

export default function ScrollTop() {
  const { pathname } = useLocation();
  const { isMobile } = useAppContext();

  useEffect(() => {
    if (!isMobile) return;

    window.scrollTo(0, 0);
  }, [pathname, isMobile]);

  return null;
}
