import React from "react";
import styles from "./landing.module.css";
import { useAppContext } from "../../../../store/AppContext";

function Landing({ scrollDown }: { scrollDown: () => void }) {
  const { isMobile } = useAppContext();

  if (isMobile) return null;

  return (
    <div className={styles.Outer}>
      &nbsp;
      <div>
        <p className={styles.Title}>Fitness Helper</p>
        <p className={styles.SubTitle}>
          Maximize Your Impact as a Trainer: Simplify Your Workflow with
          Efficient and Automated Tools
        </p>
      </div>
      <div className={styles.ScrollDown} onClick={scrollDown}>
        <div className={styles.Scroll}></div>
        <div className={styles.Arrow}></div>
      </div>
    </div>
  );
}

export default Landing;
