import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Today from "../components/today/Today";
import Layout from "./layout/Layout";
import User from "../components/user/User";
import RequireAuth from "./RequireAuth";
import Authenticate from "../components/authenticate/Authenticate";
import EditEventType from "../components/user/event-type/EditEventType";
import ErrorBoundary from "./ErrorBoundary";
import { PATHS } from "../firebase/types";
import PageNotFound from "./PageNotFound";
import { useAppContext } from "../store/AppContext";

const Public = React.lazy(() => import("../components/public/Public"));
const DynamicProHandle = React.lazy(
  () => import("../components/dynamic-pro-handle/DynamicProHandle")
);
const DynamicProEventType = React.lazy(
  () => import("../components/dynamic-pro-event-type/DynamicProEventType")
);
const EventByID = React.lazy(() => import("../components/today/EventByID"));
const Fun = React.lazy(() => import("../components/fun/Fun"));
const Stats = React.lazy(() => import("../components/stats/Stats"));
const Billing = React.lazy(() => import("../components/billing/Billing"));
const EditMicroCycle = React.lazy(
  () => import("../components/fun/MicroCycleByID")
);

function Routing() {
  const { isMobile } = useAppContext();

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route
          index
          element={
            isMobile ? (
              <RequireAuth>
                <Today />
              </RequireAuth>
            ) : (
              <Today />
            )
          }
        />
        <Route
          path={`/${PATHS.PUBLIC}`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <RequireAuth>
                  <Public />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`/${PATHS.USER}`}
          element={
            <RequireAuth>
              <User />
            </RequireAuth>
          }
        />
        <Route path={`/${PATHS.AUTHENTICATE}`} element={<Authenticate />} />
        <Route
          path={`/${PATHS.USER}/${PATHS.EDIT_EVENT_TYPE}/:id`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <RequireAuth>
                  <EditEventType />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`/${PATHS.EVENTS}/:id`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <EventByID />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`/${PATHS.PRO}/:pro_handle`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <DynamicProHandle />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`/${PATHS.PRO}/:pro_handle/:event_link`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <RequireAuth>
                  <DynamicProEventType />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`/${PATHS.WORK}`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <RequireAuth>
                  <Fun />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`/${PATHS.WORK}/${PATHS.WORKOUT}/:id`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <RequireAuth>
                  <EditMicroCycle />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path={`/${PATHS.STATS}`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <RequireAuth>
                  <Stats />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`/${PATHS.BILLING}`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <RequireAuth>
                  <Billing />
                </RequireAuth>
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
}

export default Routing;
