import React, { useEffect } from "react";
import { useAppContext } from "../../../store/AppContext";
import EventTypeListElement from "./EventTypeListElement";
import classes from "./event-type.module.css";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../firebase/types";

function EventTypes() {
  const { currentUser, eventTypes, get_event_types } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    get_event_types();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!currentUser || currentUser.role !== "pro") return null;

  const openEditEventType = () =>
    navigate(`/${PATHS.USER}/${PATHS.EDIT_EVENT_TYPE}/new`);

  return (
    <>
      <ul className={classes.List}>
        {eventTypes?.map((ev: any, idx: number) => (
          <EventTypeListElement event={ev} key={idx} />
        ))}
      </ul>
      <p className="Button" onClick={openEditEventType}>
        Create a new Event
      </p>
    </>
  );
}

export default EventTypes;
