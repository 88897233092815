import React, { useState } from "react";
import { google_sign_in } from "../../firebase/auth";
import GoogleButton from "./GoogleButton";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { PATHS } from "../../firebase/types";
import { sign_in } from "../../firebase/auth";
import { sign_up } from "../../firebase/auth";

function Authenticate() {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectTo = location.state?.from?.pathname || `/${PATHS.USER}`;

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [error, setError] = useState("");

  const [error1, setError1] = useState("");
  const handleError = (error: any) => setError1(error);
  const handleGoogleSignIn = () => {
    setError("");
    setError1("");
    google_sign_in(() => navigate(redirectTo), handleError);
  };

  const login = () => {
    setError("");
    setError1("");
    if (!email) {
      setError("provide username");
      return;
    }
    if (!password) {
      setError("provide password");
      return;
    }

    // setShowSpinner("spinner");
    sign_in(
      email,
      password,
      () => {
        // resetInputs();
        navigate(redirectTo);
      },
      (error: any) => {
        // setShowSpinner("");
        setError(error);
      }
    );
  };
  const createAccount = () => {
    setError("");
    setError1("");
    // setShowSpinner("spinner");
    sign_up(
      email,
      password,
      () => {
        // setShowSpinner("");
        // resetInputs();
        navigate(redirectTo);
      },
      (error: any) => {
        // setShowSpinner("");
        setError(error);
      }
    );
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <p className="Title_Bigger TextAlignCenter">Authenticate</p>
      <br />

      <label>
        <span>Email</span>
        <input
          placeholder="..."
          value={email}
          onChange={(ev) => {
            setEmail(ev.target.value);
            setEmailErr("");
          }}
        />
      </label>
      {emailErr && <p className="ErrorParagraph">{emailErr}</p>}
      <br />

      <label>
        <span>Password</span>
        <input
          type="password"
          placeholder="***"
          value={password}
          onChange={(ev) => {
            setPassword(ev.target.value);
            setPasswordErr("");
          }}
        />
      </label>
      {passwordErr && <p className="ErrorParagraph">{passwordErr}</p>}
      <br />

      {email && password ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="Grid2Columns"
        >
          <p className="Button" onClick={login}>
            Login
          </p>
          <p className="Button Secondary" onClick={createAccount}>
            Create account
          </p>
        </motion.div>
      ) : null}
      {error && (
        <p className="ErrorParagraph" style={{ paddingTop: "16px" }}>
          {error}
        </p>
      )}

      <p
        style={{
          color: "var(--color-gray)",
          textAlign: "center",
          padding: "10px 0",
        }}
      >
        Or use a Google account:
      </p>

      <GoogleButton onClick={handleGoogleSignIn} />

      {error1 && <p className="ErrorParagraph">{error1}</p>}
    </motion.div>
  );
}

export default Authenticate;
