// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import { getStorage } from "firebase/storage";

import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyAuqmzNGknBnErnqYZ34AwE71zgAckW7fk",
  authDomain: "fitness-helper-romania.firebaseapp.com",
  projectId: "fitness-helper-romania",
  storageBucket: "fitness-helper-romania.appspot.com",
  messagingSenderId: "434229660393",
  appId: "1:434229660393:web:0d65a6041e0c04d190be78",
  measurementId: "G-6J3TVX0FNR",
  // apiKey: "AIzaSyBLLGBsoFPfKEzRiEwwScTtzX0qwBD2gyI",
  // authDomain: "fitness-helper-c3c2a.firebaseapp.com",
  // projectId: "fitness-helper-c3c2a",
  // storageBucket: "fitness-helper-c3c2a.appspot.com",
  // messagingSenderId: "505184002373",
  // appId: "1:505184002373:web:de004093e4800a8c96de2c",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const analytics = getAnalytics(app);
// const perf = getPerformance(app);

export const auth = getAuth(app);
export const firestore = getFirestore(app);

export default app;
export const providerGoogle = new GoogleAuthProvider();
