import React from "react";
import { sign_out } from "../../firebase/auth";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../firebase/types";

function SignOut() {
  const navigate = useNavigate();

  const userSignOut = () => {
    sign_out(
      () => navigate(`/${PATHS.USER}`),
      () => {}
    );
  };

  return (
    <div
      style={{
        borderTop: "var(--gray-border-match-yellow)",
        paddingTop: "7px",
        marginTop: "17px",
      }}
    >
      <p className="Button Secondary" onClick={userSignOut}>
        Log out
      </p>
    </div>
  );
}

export default SignOut;
