import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./explanation.module.css";
import SvgToday from "../../../../assets/svg/now.svg";
import SvgTodayOn from "../../../../assets/svg/now_on.svg";

function Info_Today() {
  const { pathname } = useLocation();
  const [hover, setHover] = useState(false);
  const toggleHover = () => setHover((state) => !state);

  if (pathname !== "/") return null;

  return (
    <>
      <div className={styles.LineTitle}>
        <img
          src={hover ? SvgToday : SvgTodayOn}
          alt="Events"
          onMouseOver={toggleHover}
          onMouseOut={toggleHover}
        />
        <p className="Title_Bigger">Upcoming events</p>
      </div>
      <br />
      <p>Showing a list of events that you have schedulled to do.</p>
      <p>Clicking an event will open and display Event Details.</p>
      <br />
      <p>The following details are listed for each Event:</p>
      <ul className={styles.ExplanationUL}>
        <li>Event (Type) Name</li>
        <li>Location details</li>
        <li>Timeframe</li>
      </ul>
      <br />

      <p>Not seeing any list element? You have no upcoming events.</p>
    </>
  );
}

export default Info_Today;
