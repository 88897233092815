import React from "react";
import { useAppContext } from "../../store/AppContext";
import ProfilePhoto from "./ProfilePhoto";
import classes from "./user.module.css";
import MainInfo from "./MainInfo";
import { motion } from "framer-motion";
import EventTypes from "./event-type/EventTypes";
import Handle from "./handle/Handle";
import SignOut from "./SignOut";
import About from "./about/About";
import ButtonUpgradeAccount from "./ButtonUpgradeAccount";

function User() {
  const { currentUser } = useAppContext();

  if (!currentUser) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={classes.Outer}
    >
      <div className={classes.Header}>
        <ProfilePhoto />
        <MainInfo />
      </div>

      <About />

      <Handle />
      <EventTypes />

      <ButtonUpgradeAccount />
      <SignOut />
    </motion.div>
  );
}

export default User;
