import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { PATHS } from "../../../../firebase/types";
import styles from "./explanation.module.css";
import Svg from "../../../../assets/svg/user.svg";
import SvgOn from "../../../../assets/svg/user_on.svg";

function Info_User() {
  const { pathname } = useLocation();
  const [hover, setHover] = useState(false);
  const toggleHover = () => setHover((state) => !state);

  if (pathname !== `/${PATHS.USER}`) return null;

  return (
    <>
      <div className={styles.LineTitle}>
        <img
          src={hover ? Svg : SvgOn}
          alt="User"
          onMouseOver={toggleHover}
          onMouseOut={toggleHover}
        />
        <p className="Title_Bigger">User</p>
      </div>
      <br />
      <p>The User section includes options for:</p>
      <ul className={styles.ExplanationUL}>
        <li>Minimal personal details</li>
        <li>List of Event Types</li>
        <li>User unique Handle</li>
        <li>Logging out</li>
      </ul>
      <br />

      <p>
        Make sure to set a unique Handle so people can join your event types.
      </p>
      <p>Your Handle is used to visit your User Events page.</p>
      <br />

      <p>
        Assign a plan to an Event Type with multiple participants or assign the
        client to a training plan
      </p>
      <br />

      <p>
        Preview <b>Event Types</b> and your <b>User Events</b> to check your
        settings.
      </p>
      <p>Copy the address and share by pasting the details.</p>
      <br />

      <p>
        Not seeing any list element? <b>Create</b> your first Event Type.
      </p>
      <p>
        Can't create Event Types? <b>Upgrade</b> your account to be able to
        create Event Types.
      </p>
    </>
  );
}

export default Info_User;
