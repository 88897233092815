import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  QuerySnapshot,
  // setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { ConsoleLog } from "./general";
import { firestore } from "./config";
import {
  Event,
  EventType,
  User,
  Microcycle,
  Anamneza,
  EarlyAccess,
} from "./types";

const COLL_NAME_USERS = "users";
const COLL_NAME_ANAMNEZA = "anamneza";
const COLL_NAME_EVENT_TYPES = "event-types";
const COLL_NAME_EVENTS = "events";
const COLL_NAME_MICROCYCLE = "micro-cycles";

const COLL_NAME_EARLY_ACCESS = "early-access";
const COLLECTION_EARLY_ACCESS = collection(firestore, COLL_NAME_EARLY_ACCESS);

const COLLECTION_USERS = collection(firestore, COLL_NAME_USERS);
const COLLECTION_ANAMNEZA = collection(firestore, COLL_NAME_ANAMNEZA);
const COLLECTION_EVENT_TYPES = collection(firestore, COLL_NAME_EVENT_TYPES);
const COLLECTION_EVENTS = collection(firestore, COLL_NAME_EVENTS);
const COLLECTION_MICROCYCLES = collection(firestore, COLL_NAME_MICROCYCLE);

// NOT USED YET
const getDocsFromCollection = async (
  collection: CollectionReference,
  nmb?: number
) => {
  try {
    const collectionQuery = query(collection, limit(nmb ? nmb : 99));
    const snapShot = await getDocs(collectionQuery);
    let data: object[] = [];
    snapShot.forEach((snap) => {
      data.push({ id: snap.id, ...snap.data() });
    });
    return data;
  } catch (error) {
    ConsoleLog("getDocsFromCollection", error);
    return { err: error };
  }
};

// GENERIC
const getSnapshotDataObject = async (reqQuery: DocumentReference) => {
  const snapShot: DocumentSnapshot<DocumentData> = await getDoc(reqQuery);
  if (!snapShot.exists()) return null;

  const data = { id: snapShot.id, ...snapShot.data() };
  return data as any;
};
const getSnapshotDataArray = async (reqQuery: Query<DocumentData>) => {
  const snapShot: QuerySnapshot<DocumentData> = await getDocs(reqQuery);
  let data: any[] = [];
  snapShot.forEach((snap) => {
    if (data === null) data = [];
    data.push({ id: snap.id, ...snap.data() });
  });
  return data && data.length ? data : null;
};
//
const createDocInCollection = async (
  collection: CollectionReference,
  request: object
) => {
  const docRef = await addDoc(collection, request);
  let docData = await getSnapshotDataObject(docRef);
  return docData;
};
const updateDocInCollection = async (path: string, request: any) => {
  const docRef = doc(firestore, path);
  await updateDoc(docRef, request);
};
const deleteDocByPath = async (path: string) => {
  const docRef = doc(firestore, path);
  await deleteDoc(docRef);
};

// CREATE
export const createUser = async (request: User) => {
  return await createDocInCollection(COLLECTION_USERS, request);
};
export const createAnamneza = async (request: Anamneza) => {
  return await createDocInCollection(COLLECTION_ANAMNEZA, request);
};
export const createEventType = async (request: EventType) => {
  return await createDocInCollection(COLLECTION_EVENT_TYPES, request);
};
export const createEvent = async (request: Event) => {
  return await createDocInCollection(COLLECTION_EVENTS, request);
};
export const createMicrocycle = async (request: Microcycle) => {
  return await createDocInCollection(COLLECTION_MICROCYCLES, request);
};
export const createEarlyAccess = async (request: EarlyAccess) => {
  return await createDocInCollection(COLLECTION_EARLY_ACCESS, request);
};

// UPDATE
export const updateUser = async (request: User) => {
  await updateDocInCollection(`${COLL_NAME_USERS}/${request.id}`, request);
};
export const updateAnamneza = async (request: Anamneza) => {
  await updateDocInCollection(`${COLL_NAME_ANAMNEZA}/${request.id}`, request);
};
export const updateEventType = async (request: EventType) => {
  await updateDocInCollection(
    `${COLL_NAME_EVENT_TYPES}/${request.id}`,
    request
  );
};
export const updateEvent = async (request: Event) => {
  await updateDocInCollection(`${COLL_NAME_EVENTS}/${request.id}`, request);
};
export const updateMicrocycle = async (request: Microcycle) => {
  await updateDocInCollection(`${COLL_NAME_MICROCYCLE}/${request.id}`, request);
};

// DELETE
export const deleteEventType = async (id: string) => {
  await deleteDocByPath(`${COLL_NAME_EVENT_TYPES}/${id}`);
};
export const deleteEvent = async (id: string) => {
  await deleteDocByPath(`${COLL_NAME_EVENTS}/${id}`);
};
export const deleteMicrocycle = async (id: string) => {
  await deleteDocByPath(`${COLL_NAME_MICROCYCLE}/${id}`);
};

// GET
export const getEarlyAccessByEmail = async (email: string) => {
  const reqQuery = query(
    collection(firestore, COLL_NAME_EARLY_ACCESS),
    where("email", "==", email)
  );
  const result: EarlyAccess[] | null = await getSnapshotDataArray(reqQuery);
  return result ? result[0] : null;
};
export const getUserByUID = async (uid: string) => {
  const reqQuery = query(
    collection(firestore, COLL_NAME_USERS),
    where("uid", "==", uid)
  );
  const result: User[] | null = await getSnapshotDataArray(reqQuery);
  return result ? result[0] : null;
};
export const getAnamnezaByUID = async (uid: string) => {
  const reqQuery = query(
    collection(firestore, COLL_NAME_ANAMNEZA),
    where("uid", "==", uid)
  );
  const result: Anamneza[] | null = await getSnapshotDataArray(reqQuery);
  return result ? result[0] : null;
};
export const getUserByHandle = async (handle: string) => {
  const reqQuery = query(
    collection(firestore, COLL_NAME_USERS),
    where("handle", "==", handle.toLowerCase())
  );
  const result: User[] | null = await getSnapshotDataArray(reqQuery);
  return result ? result[0] : null;
};
export const getUsersByHandle = async (handle: string) => {
  const reqQuery = query(
    collection(firestore, COLL_NAME_USERS),
    // where("role", "==", "pro"),
    where("handle", ">=", handle.toLowerCase()),
    where("handle", "<=", handle.toLowerCase() + "\uf8ff")
  );
  const result: User[] | null = await getSnapshotDataArray(reqQuery);
  return result;
};
export const getUsersByDisplayName = async (search: string) => {
  console.log("Improve user search.", search);
  const reqQuery = query(
    collection(firestore, COLL_NAME_USERS),
    where("displayName", ">=", search),
    where("displayName", "<=", search + "\uf8ff")
  );
  const result: User[] | null = await getSnapshotDataArray(reqQuery);
  return result;
};
export const getEventByID = async (id: string) => {
  const eventRef = doc(firestore, `${COLL_NAME_EVENTS}/${id}`);
  const result: Event | null = await getSnapshotDataObject(eventRef);
  return result;
};
export const getEventTypeByID = async (id: string) => {
  const eventRef = doc(firestore, `${COLL_NAME_EVENT_TYPES}/${id}`);
  const result: Event | null = await getSnapshotDataObject(eventRef);
  return result;
};
export const getEventsByClientUID = async (uid: string) => {
  const reqQuery = query(
    collection(firestore, COLL_NAME_EVENTS),
    where("attendeeUIDs", "array-contains", uid)
  );
  const result: Event[] | null = await getSnapshotDataArray(reqQuery);
  return result;
};
export const getEventsByProUID = async (uid: string) => {
  const reqQuery = query(
    collection(firestore, COLL_NAME_EVENTS),
    where("creatorUID", "==", uid)
  );
  const result: Event[] | null = await getSnapshotDataArray(reqQuery);
  return result;
};
export const getEventTypesByLink = async (link: string) => {
  const reqQuery = query(
    collection(firestore, COLL_NAME_EVENT_TYPES),
    where("link", "==", link)
  );
  const result: EventType[] | null = await getSnapshotDataArray(reqQuery);
  return result;
};
export const getEventTypesByProUID = async (uid: string) => {
  const reqQuery = query(
    collection(firestore, COLL_NAME_EVENT_TYPES),
    where("creatorUID", "==", uid)
  );
  const result: EventType[] | null = await getSnapshotDataArray(reqQuery);
  return result;
};
export const getRecentEventTypes = async () => {
  const reqQuery = query(
    collection(firestore, COLL_NAME_EVENT_TYPES),
    orderBy("createdAt"),
    limit(10)
  );
  const result: EventType[] | null = await getSnapshotDataArray(reqQuery);
  return result;
};
export const getEventByCreatorUIDAndTimestamp = async (
  timestamp: number,
  creatorUID: string
) => {
  const reqQuery = query(
    collection(firestore, COLL_NAME_EVENTS),
    where("timestamp", "==", timestamp),
    where("creatorUID", "==", creatorUID)
    // where("attendeeUIDs", "array-contains", attendeeUID)
  );
  const result: Event[] | null = await getSnapshotDataArray(reqQuery);
  return result ? result[0] : null;
};
export const getMicrocyclesByProUID = async (uid: string) => {
  const reqQuery = query(
    collection(firestore, COLL_NAME_MICROCYCLE),
    where("creatorUID", "==", uid),
    limit(10)
  );
  const result: Microcycle[] | null = await getSnapshotDataArray(reqQuery);
  return result;
};
export const getMicrocycleByID = async (id: string) => {
  const eventRef = doc(firestore, `${COLL_NAME_MICROCYCLE}/${id}`);
  const result: Microcycle | null = await getSnapshotDataObject(eventRef);
  return result;
};

// const parseDocumentSnapshot = (snapShot: DocumentSnapshot<DocumentData>) => {
//   let data;
//   if (snapShot.exists()) {
//     data = snapShot.data();
//   }
//   return data;
// };

// export const get_frontend_db = async () => {
//   const settings = await getDoc(SET_DOC_REF);
//   const pages = await getPages();
//   const logoRef = ref(storage, "settings/logo.svg");
//   const logoUrl = await getDownloadURL(logoRef);

//   const bc = await getBasicComponents();
//   const wc = await getWrapperComponents();
//   const ls = await getLayoutSections();

//   return await Promise.all([settings, pages, logoUrl, bc, wc, ls])
//     .then((messages) => {
//       let parsed: any = {};
//       parsed.settings = parseDocumentSnapshot(messages[0]);
//       parsed.pages = messages[1];
//       parsed.logoUrl = messages[2];
//       parsed.basicComponents = messages[3];
//       parsed.wrappers = messages[4];
//       parsed.layoutSections = messages[5];

//       return parsed;
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };

// if ever is needed a live connection to the db:
// export const listenToSettingsDoc = () => {
//   onSnapshot(SET_DOC_REF, (docSnapShot) => {
//     if (docSnapShot.exists()) {
//       const data = docSnapShot.data();
//       console.log(`ON snap SHOT data CHANGED: ${JSON.stringify(data)}`);
//     }
//   });
// };
