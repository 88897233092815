import React from "react";
import InfoAuthenticate from "./Info_Authenticate";
import InfoUser from "./Info_User";
import InfoPublic from "./Info_Public";
import InfoStats from "./Info_Stats";
import InfoWork from "./Info_Work";
import InfoToday from "./Info_Today";
import { useAppContext } from "../../../../store/AppContext";

function ExplanationSection() {
  const { isMobile } = useAppContext();

  if (isMobile) return null;

  return (
    <>
      <InfoAuthenticate />
      <InfoPublic />
      <InfoWork />
      <InfoToday />
      <InfoStats />
      <InfoUser />
    </>
  );
}

export default ExplanationSection;
