import React, { useEffect, useState } from "react";
import { Calendar } from "react-date-range";

function DateRangeCalendar({ onChange, receivedDate }) {
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (receivedDate) setDate(new Date(receivedDate));
  }, [receivedDate]);

  return (
    <Calendar
      onChange={(item) => {
        setDate(item);
        onChange(item);
      }}
      date={date}
    />
  );
}

export default DateRangeCalendar;
