import React from "react";
import styles from "../layout.module.css";
import { useAppContext } from "../../../store/AppContext";

function Footer() {
  const { isMobile } = useAppContext();
  if (isMobile) return null;

  return <div className={styles.Desktop_Footer}>. . .</div>;
}

export default Footer;
