import React from "react";
import "./range-picker.css";
import RangePicker from "./RangePicker";
import { DateRangeSelection, RepeatDateSelection } from "../../firebase/types";

interface ORPProps {
  selectRange: (selection: DateRangeSelection) => void;
  selection?: RepeatDateSelection;
}

function OpenRangePicker({ selectRange, selection }: ORPProps) {
  return <RangePicker selection={selection} onChange={selectRange} />;
}

export default OpenRangePicker;
