import styles from "./modal.module.css";
import { motion } from "framer-motion";
import { useAppContext } from "../../store/AppContext";
import { createPortal } from "react-dom";

export default function Modal() {
  const { modal, setModal } = useAppContext();

  if (!modal) return null;

  const closeModal = () => setModal();
  const cancelPropagation = (
    ev: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => ev.stopPropagation();

  return createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.ModalBackdrop}
      onClick={closeModal}
    >
      <div className={styles.Modal} onClick={cancelPropagation}>
        {modal?.props?.children}
      </div>
    </motion.div>,
    document.body
  );
}
