import React from "react";
import GoogleLogo from "../../assets/svg/google_logo.svg";
import classes from "./authenticate.module.css";

interface PropTypes {
  onClick: any;
}

function GoogleButton({ onClick }: PropTypes) {
  return (
    <div onClick={onClick} className={classes.GoogleButton}>
      <div className={classes.Logo}>
        <img src={GoogleLogo} alt="Google" />
      </div>
      <div className={classes.Text}>Sign in with Google</div>
    </div>
  );
}

export default GoogleButton;
